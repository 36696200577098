
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
@Component({
  components: { DefaultLayout }
})
export default class extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Utility Functions */
  goBack() {
    this.$router.back();
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
